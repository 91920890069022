<template>
  <div class="page">   <h1 class="title">您已经迷路了，请查看路径是否正确</h1>
    <div class="box">
      <img src="https://file.cdn.tanchi.shop/prod/system/af8c2e633d3db00bf85d97e3d16404b2?x-oss-process=style/gif_600">
      <div class="action">
        <el-button @click="$router.push('/')">回到首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.page {
  height: 100vh;
  flex-direction: column;
  .flexCenter;
  .title {
    margin-bottom: 30px;
  }
  .box {
    .border;
    .radius;
    padding: 20px;
    background: var(--background);
    .action {
      margin-top: 20px;
        .flexCenter;
    }
  }
}
</style>
