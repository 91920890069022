import { render, staticRenderFns } from "./404.vue?vue&type=template&id=78fc2f20&scoped=true&"
import script from "./404.vue?vue&type=script&lang=js&"
export * from "./404.vue?vue&type=script&lang=js&"
import style0 from "./404.vue?vue&type=style&index=0&id=78fc2f20&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78fc2f20",
  null
  
)

export default component.exports